body{
    background-color: #f0f0f0; /* Cor de fundo desejada */
    margin: 0px; /* Para remover as margens padrão do corpo */
    padding: 0; /* Para remover o padding padrão do corpo */
    /*font-family: 'Arial', sans-serif; /* Especifica a fonte padrão para o corpo */
    font-family: serif;
    height: 100vh;
    text-align: justify;

    color: #333333;
  }

  #root{
    overflow: hidden;
    height: 100vh;
  }
  